<template>
  <div class="footBox">
    <footerStar></footerStar>
    <div class="fontContent">
      <el-row>
        <el-col :xs="0" :sm="10" :md="8" :lg="7" :xl="7">
          <img class="foot_img" :src="owlUrl" alt=""/>
        </el-col>
        <el-col :xs="24" :sm="10" :md="8" :lg="12" :xl="12">
          <p class="font_title">联系我们</p>
          <ul class="font_ul">
            <!--            <li class="foot_sub">迈吉客科技（北京）有限公司</li>-->
            <el-row>
              <el-col :xs="20" :sm="24" :md="24" :lg="11" :xl="11">
                <li>{{ footerMsg.sw.label }}：
                  <a :href="'mailto:'+footerMsg.sw.email+'?subject=test&cc=&subject='+footerMsg.sw.label+'&body='">
                    {{ footerMsg.sw.email }}</a>
                </li>

                <li>{{ footerMsg.mt.label }}：
                  <a :href="'mailto:'+footerMsg.mt.email+'?subject=test&cc=&subject='+footerMsg.mt.label+'&body='">
                    {{ footerMsg.mt.email }}</a>
                </li>

              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <li>{{ footerMsg.jl.label }}：
                  <a :href="'mailto:'+footerMsg.jl.email+'?subject=test&cc=&subject='+footerMsg.jl.label+'&body='">
                    {{ footerMsg.jl.email }}</a>
                </li>
                <li>
                  <p class="">{{ footerMsg.address }}</p>
                </li>
              </el-col>
            </el-row>
          </ul>
        </el-col>
        <el-col :xs="3" :sm="2" :md="4" :lg="2" :xl="2">
          <div class="font_titleBox right">
            <p class="font_title_ewm">{{ clientWidth > 1200 ? '迈吉客公众号' : '公众号' }}</p>
            <el-image class="ewm" :src="ewmUrl" fit="fill"></el-image>
          </div>
        </el-col>
        <el-col :xs="3" :sm="2" :md="4" :lg="3" :xl="3">
          <div class="font_titleBox">
            <p class="font_title_ewm">{{ clientWidth > 1200 ? '迈吉客商务合作' : '商务合作' }}</p>
            <el-image class="ewm" :src="ewmUrl2" fit="fill"></el-image>
          </div>
        </el-col>
      </el-row>
      <div class="copyRight">
        <p>{{ footerMsg.icp }}</p>
        <div><span class="link" @click.stop="goLink">京ICP备16035435号</span> |
          网络文化经营许可证号:京网文[2020]2167-338号 | <span class="link" @click.stop="goLink">ICP许可证编号:京B2-20202664号</span></div>
      </div>

      <!--      <div class="mshow">-->
      <!--        <p class="font_title">联系我们</p>-->
      <!--        <ul class="font_ul">-->
      <!--          &lt;!&ndash;          <li class="foot_sub">迈吉客科技（北京）有限公司</li>&ndash;&gt;-->
      <!--          <li>{{ footerMsg.sw.label }}：-->
      <!--            <a :href="'mailto:'+footerMsg.sw.email+'?subject=test&cc=&subject='+footerMsg.sw.label+'&body='">-->
      <!--              {{ footerMsg.sw.email }}</a>-->
      <!--          </li>-->

      <!--          <li>{{ footerMsg.mt.label }}：-->
      <!--            <a :href="'mailto:'+footerMsg.mt.email+'?subject=test&cc=&subject='+footerMsg.mt.label+'&body='">-->
      <!--              {{ footerMsg.mt.email }}</a>-->
      <!--          </li>-->

      <!--          &lt;!&ndash;          <li>产品合作：&ndash;&gt;-->
      <!--          &lt;!&ndash;            <a href="mailto:product@magics.ai?subject=test&cc=&subject=产品合作&body=">&ndash;&gt;-->
      <!--          &lt;!&ndash;              product@magics.ai</a>&ndash;&gt;-->
      <!--          &lt;!&ndash;          </li>&ndash;&gt;-->
      <!--          <li>{{ footerMsg.jl.label }}：-->
      <!--            <a :href="'mailto:'+footerMsg.jl.email+'?subject=test&cc=&subject='+footerMsg.jl.label+'&body='">-->
      <!--              {{ footerMsg.jl.email }}</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <p class="">{{ footerMsg.address }}</p>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--        <div class="font_titleBox">-->
      <!--          <p class="font_title_ewm">商务合作</p>-->
      <!--          <el-image class="ewm" :src="ewmUrl2" fit="fill"></el-image>-->
      <!--        </div>-->
      <!--        <div class="font_titleBox" style="right: 1rem">-->
      <!--          <p class="font_title_ewm">迈吉客公众号</p>-->
      <!--          <el-image class="ewm" :src="ewmUrl" fit="fill"></el-image>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="mshow copyRight clearfix">-->
      <!--        &lt;!&ndash;        <p class="">公司地址：北京市海淀区奥北科技园领智中心C座</p>&ndash;&gt;-->
      <!--        <p class="">{{ footerMsg.icp }}</p>-->
      <!--        <p><span class="link" @click.stop="goLink">京ICP备16035435号</span> | 网络文化经营许可证号:京网文[2020]2167-338号 |-->
      <!--          <span class="link" @click.stop="goLink">ICP许可证编号:京B2-20202664号</span></p>-->
      <!--      </div>-->

    </div>
  </div>
</template>

<script>
import footerStar from './footer-star'
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(['clientWidth']),
  },
  components: {
    footerStar
  },
  data() {
    return {
      ewmUrl: require('@/assets/image/magicsEWM.jpg'),
      ewmUrl2: require('@/assets/image/magicsEWMSW.png'),
      owlUrl: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/foot_logo_left.png',

      footerMsg: {
        sw: {
          label: '商务合作',
          email: 'bd@magics.ai'
        },
        mt: {
          label: '市场合作',
          // email:'business@magics.ai'
          email: 'marketing@magics.ai'
        },
        jl: {
          label: '简历投递',
          email: 'talent@magics.ai'
        },
        address: '公司地址：北京市海淀区奥北科技园领智中心C座',
        // icp:'@2015-2020 magics.ai, all rights reserved 迈吉客科技(北京)有限公司 ,   京ICP备16035435号-5'
        icp: '@2015-2020 magics.ai, all rights reserved 迈吉客科技(北京)有限公司'
      }
    }
  },
  methods: {
    goLink() {
      window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
    }
  }
}
</script>

<style lang="less" scoped>
.footBox {
  position: relative;
  width: 100%;
  height: 240px;
  font-size: 0;
  background: rgba(24, 33, 57, 1);
  font-weight: 400;

  .foot_img {
    height: 185px;
  }

  .fontContent {
    width: 1200px;
    margin: 0 auto;
    padding: 18px 0 0;
    color: rgba(255, 255, 255, 0.80);

    .font_title {
      font-size: 20px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 30px;
    }

    .font_ul {
      font-size: 13px;

      .foot_sub {
        /*margin-bottom: .18rem;*/
      }

      li {
        height: 28px;
        line-height: 28px;
        margin-bottom: 10px;

        a {
          color: rgba(255, 255, 255, 0.80);
        }
      }

      .foot_li {
        &:hover {
          cursor: pointer;
          color: #66b1ff;
        }
      }
    }

    .font_titleBox {
      text-align: center;
      margin-right: 10px;

      .font_title_ewm {
        font-size: 16px;
        color: #fff;
        font-weight: 400;
        text-align: center;
      }

      .ewm {
        margin: 20px 0 0 0;
        width: 90px;
        height: 90px;
        padding: 3px;
        background: #fff;
        border-radius: 5px;
      }
    }

    .copyRight {
      /*font-size: 16px;*/
      /*margin-top: .28rem;*/
      font-size: 13px;
      margin-top: -18px;
      color: #9c8fa9;
      line-height: 20px;
      text-align: center;

      p {
        text-align: left
      }
    }
  }
}

.link {
  cursor: pointer;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .footBox {
    height: 280px;

    .fontContent {
      width: 100%;

      .copyRight {
        margin: 0;

        p {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .footBox {
    .foot_img {
      height: 160px;
    }

    .fontContent {
      width: calc(100% - 0.4rem);
      padding: .2rem .2rem 0;
      font-size: 0;

      .font_title {
        font-size: 20px;
        margin-bottom: .1rem;
      }

      .font_ul {
        font-size: 13px;

        .foot_sub {
          margin-bottom: .14rem;
        }

        li {
          height: .20rem;
          line-height: .20rem;
          margin-bottom: .1rem;
        }
      }

      .font_titleBox {
        margin-top: 0;
        position: absolute;
        top: 0.25rem;
        right: .5rem;
        text-align: center;

        &.right {
          right: -.28rem;
        }

        .font_title_ewm {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          font-weight: 400;
          text-align: center;
        }

        .ewm {
          margin: 0.1rem;
          width: 0.6rem;
          height: 0.6rem;
          padding: 0.02rem;
          background: #fff;
          border-radius: 5px;
        }
      }

      .copyRight {
        font-size: 12px;
        margin: .1rem 0 0 0;
        line-height: 18px;

        p {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footBox {
    height: 260px;

    .fontContent {
      .copyRight {
        margin: 0;
      }
    }
  }
}
</style>
